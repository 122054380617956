import React from 'react'
import Fade from 'react-reveal/Fade';
import './Xanax.css'

const Xanax = () => {
    return (
        <div className='xanax'>
                <div className="xanax-content">
                    <Fade top>
                    <h1>XANAX ADDICTION</h1>
                    </Fade>
            </div>
        </div>
    )
}

export default Xanax
