import React from 'react'
import Fade from 'react-reveal/Fade';
import './Op.css'


const OP = () => {
    return (
        <>
        <div className='mh-op submenu'>

                <div className="mh-op-content">
                    <Fade top>
                    <h1>Outpatient Program</h1>
                    </Fade>
                </div>
            </div>
        </>
    )
}

export default OP